button {
    padding: 8px;
    margin-bottom: 4px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #e0e0e0;
  }
  