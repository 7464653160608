.node-action-drawer {
    position: fixed;
    right: 0;
    top: 0;
    width: 200px;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
  }
  
  .node-action-drawer button {
    display: flex;
    align-items: center;
    gap: 10px;
    background: none;
    border: none;
    color: #333;
    font-size: 18px;
    cursor: pointer;
  }
  .node-action-button {
    padding: 5px 10px;
    margin: 2px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  /* WorkFlow.css */

/* Default size */
.react-flow-wrapper {
  height: 400px; /* Default height */
  width: 100%;   /* Take full width of the column */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .react-flow-wrapper {
    height: 300px; /* Larger height for larger screens */
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .react-flow-wrapper {
    height: 400px;
  }
}
